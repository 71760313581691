import * as React from 'react';

import { SVGIconProps } from './types';

const ImageGalleryIcon = ({ size = 24, ...rest }: SVGIconProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		data-test-id="CT_component_image-gallery-icon_cms"
		viewBox="0 0 24 24"
		fill="none"
		{...rest}
	>
		<path
			d="M16.6 19.3C18.1 19.3 19.3 18.1 19.3 16.6V4.7C19.3 3.2 18.1 2 16.6 2H4.7C3.2 2 2 3.2 2 4.7V16.6C2 18.1 3.2 19.3 4.7 19.3H16.6ZM17.3 11L14.1 7.8C13.7 7.4 13.1 7.4 12.8 7.8L4 16.7V16.6V4.7C4 4.3 4.3 4 4.7 4H16.6C17 4 17.3 4.3 17.3 4.7V11ZM13.5 9.9L16.9 13.3C17 13.4 17.1 13.5 17.3 13.5V16.5C17.3 16.9 17 17.2 16.6 17.2H6.1L13.5 9.9Z"
			fill="#111111"
		/>
		<path
			d="M7.40005 5.3C6.30005 5.3 5.30005 6.2 5.30005 7.4C5.30005 8.6 6.20005 9.5 7.40005 9.5C8.60005 9.5 9.50005 8.6 9.50005 7.4C9.50005 6.2 8.50005 5.3 7.40005 5.3ZM7.40005 7.7C7.20005 7.7 7.00005 7.5 7.00005 7.3C7.00005 7.1 7.20005 6.9 7.40005 6.9C7.60005 6.9 7.80005 7.1 7.80005 7.3C7.80005 7.5 7.60005 7.7 7.40005 7.7Z"
			fill="#111111"
		/>
		<path
			d="M20.9999 4.89999C20.4999 4.89999 20.0999 5.29999 20.0999 5.79999V17.4C20.0999 18.9 18.8999 20.2 17.2999 20.2H4.7999C4.2999 20.2 3.8999 20.6 3.8999 21.1C3.8999 21.6 4.2999 22 4.7999 22H17.3999C19.8999 22 21.9999 20 21.9999 17.4V5.79999C21.8999 5.29999 21.4999 4.89999 20.9999 4.89999Z"
			fill="#111111"
		/>
	</svg>
);
export default ImageGalleryIcon;

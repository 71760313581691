import * as React from 'react';

import { SVGIconProps } from './types';

const AddImage: React.FC<SVGIconProps> = props => {
	const { size, color } = props;
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.54321 8.09908C7.26707 8.09908 7.04321 8.32293 7.04321 8.59908C7.04321 8.87522 7.26707 9.09908 7.54321 9.09908C7.81936 9.09908 8.04321 8.87522 8.04321 8.59908C8.04321 8.32293 7.81936 8.09908 7.54321 8.09908ZM5.04321 8.59908C5.04321 7.21836 6.1625 6.09908 7.54321 6.09908C8.92392 6.09908 10.0432 7.21836 10.0432 8.59908C10.0432 9.97979 8.92392 11.0991 7.54321 11.0991C6.1625 11.0991 5.04321 9.97979 5.04321 8.59908Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.9612 7.98621C19.5154 7.98621 19.9647 8.43547 19.9647 8.98965V15.0103C19.9647 15.5645 19.5154 16.0138 18.9612 16.0138C18.407 16.0138 17.9578 15.5645 17.9578 15.0103V8.98965C17.9578 8.43547 18.407 7.98621 18.9612 7.98621Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.9475 12C14.9475 11.4458 15.3968 10.9966 15.951 10.9966H21.9716C22.5258 10.9966 22.9751 11.4458 22.9751 12C22.9751 12.5542 22.5258 13.0034 21.9716 13.0034H15.951C15.3968 13.0034 14.9475 12.5542 14.9475 12Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.03345 5.00092C1.03345 3.34406 2.37659 2.00092 4.03345 2.00092H18.0027C19.6595 2.00092 21.0027 3.34406 21.0027 5.00092V5.02669C21.0027 5.57897 20.555 6.02669 20.0027 6.02669C19.4504 6.02669 19.0027 5.57897 19.0027 5.02669V5.00092C19.0027 4.44863 18.555 4.00092 18.0027 4.00092H4.03345C3.48116 4.00092 3.03345 4.44863 3.03345 5.00092V19.018C3.03345 19.5703 3.48116 20.018 4.03345 20.018H18.0027C18.555 20.018 19.0027 19.5703 19.0027 19.018V18.9948C19.0027 18.4425 19.4504 17.9948 20.0027 17.9948C20.555 17.9948 21.0027 18.4425 21.0027 18.9948V19.018C21.0027 20.6749 19.6595 22.018 18.0027 22.018H4.03345C2.37659 22.018 1.03345 20.6749 1.03345 19.018V5.00092Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.7716 12.2339C13.1591 12.6274 13.1543 13.2606 12.7608 13.6481L4.59036 21.6947C4.19686 22.0822 3.56372 22.0774 3.17619 21.6839C2.78866 21.2904 2.79349 20.6572 3.18699 20.2697L11.3574 12.2231C11.7509 11.8356 12.384 11.8404 12.7716 12.2339Z"
				fill={color}
			/>
		</svg>
	);
};

AddImage.defaultProps = {
	size: 30,
	color: '#000000',
};

export default AddImage;
